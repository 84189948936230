import React from "react";
import {PureComponent} from "@reco-m/core";

export namespace SuspendSelect {
    export interface IProps extends PureComponent.IProps {
        toggle?: boolean
    }

    export interface IState extends PureComponent.IState {
        toggle?: boolean;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            toggle: false
        };

        protected getInitState(_nextProps: Readonly<P>): Readonly<S> {
            return {
                toggle: false,
            } as any;
        }

        componentWillReceiveProps(_nextProps) {
            const {toggle} = this.props; // 获取组织编码参数
            this.setState({
                toggle: toggle
            })
        }

        toggle() {
            this.setState({
                toggle: !this.state.toggle
            })
        }

        render() {
            const dom = document.querySelector('.select-content-box');
            const height = dom && dom.clientHeight > document.body.clientHeight - 100 ? '65vh' : dom ? dom.clientHeight + 'px' : ''
            return !client.showheader && <div className={this.state.toggle ? "ding-select on" : "ding-select"}>
                {
                    <>
                        <div className="select-icon" onClick={() => this.toggle()}><i className='iconoa icon-xljtoa'/>
                        </div>
                        <div className='select-content'>
                            <div className='select-content-props' style={{height: this.state.toggle ? height : ''}}>
                                <div className="select-content-box">
                                    {this.props.children}
                                </div>
                            </div>
                            <div
                                className="select-content-shadow"
                                onClick={() => {
                                    this.setState({
                                        toggle: false
                                    })
                                }}
                            />
                        </div>
                    </>
                }
            </div>;
        }
    }

}

