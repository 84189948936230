import { PureComponent } from "@reco-m/core";
import React from "react";
import { Icon } from "antd-mobile";

export namespace SuspendButton {
  export interface IProps extends PureComponent.IProps {
    multiple?: boolean;
    show?: boolean;
    open?: boolean;
    hideToggle?: boolean;
  }

  export interface IState extends PureComponent.IState {
    toggle?: boolean;
  }

  export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
    static defaultProps: any = {
      multiple: false,
      show: false,
      open: false,
      hideToggle: false
    };
    protected getInitState(_nextProps: Readonly<P>): Readonly<S> {
      return {
        toggle: false,
      } as any;
    }
  
    componentReceiveProps(nextProps: Readonly<SuspendButton.IProps>) {
      this.setState({
        toggle: nextProps.open
      })
    }
  
    toggle() {
      this.setState({
        toggle: !this.state.toggle
      })
    }
  
    render() {

      return (!client.showheader || this.props.show) ? <div className={this.props.hideToggle ? 'suspend show' : 'suspend'}>
        {
          !this.props.multiple && <div className='floating-single'>
            {this.props.children}
          </div>
        }
        {
          this.props.multiple && <>
            <div className={(this.state.toggle) ? 'floating-box open' : 'floating-box'}>
              {this.props.children}
            </div>
            {
              !this.props.hideToggle && <a className={(this.state.toggle) ? 'suspend-button selected' : 'suspend-button'} onClick={() => this.toggle()}><Icon type="down" /></a>
            }
          </>
        }
      </div> : null;
    }
  }
  
}

