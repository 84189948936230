import React from "react";

import { List } from "antd-mobile";

import { PureComponent } from "@reco-m/core";

import { getLocation } from "../utils/common";

export namespace GDMap {
    export interface IProps extends PureComponent.IProps {
        address?: string;
        title?: string;
        mapStyle?: string;
        titleStyle?: string;
    }

    export interface IState extends PureComponent.IState {
        show?: boolean;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            address: "浦东新区纳贤路800号",
            title: "地图",
            /*mapStyle: "acti_comment",
            titleStyle: "acticobt"*/
        };

        protected getInitState(_nextProps: Readonly<P>): Readonly<S> {
            return {
                show: false,
            } as any;
        }

        componentDidMount() {
            const { address } = this.props;

            address && this.renderMapStyle(address!);
        }

        renderMapStyle(address: string) {
            new AMap.plugin("AMap.Geocoder", () => {
                let geocoder = new AMap.Geocoder();
                geocoder.getLocation(address, (status: any, result: any) => {
                    if (status === "complete" && result.geocodes.length) {
                        let lnglat = result.geocodes[0].location,
                            map = new AMap.Map("spacemap", {
                                zoom: 15,
                                center: [lnglat.lng, lnglat.lat],
                            }),
                            marker = new AMap.Marker({
                                position: new AMap.LngLat(lnglat.lng, lnglat.lat),
                                title: address,
                            });

                        map.add(marker);
                    } else {
                        getLocation().then((data: any) => {
                            if (data && data.Longitude) {
                                let lnglat = {
                                        lng: data!.Longitude,
                                        lat: data!.Latitude,
                                    },
                                    map = new AMap.Map("spacemap", {
                                        zoom: 15,
                                        center: [lnglat.lng, lnglat.lat],
                                    }),
                                    marker = new AMap.Marker({
                                        position: new AMap.LngLat(lnglat.lng, lnglat.lat),
                                        title: address,
                                    });

                                map.add(marker);
                            }
                        });
                        this.setState({
                            show: true,
                        });
                    }
                });
            });
        }

        render(): React.ReactNode {
            const { title, mapStyle, titleStyle } = this.props;

            return (
                <List className={mapStyle}>
                    <List.Item className={titleStyle}>
                        <span>{title}</span>
                    </List.Item>
                    <List.Item>
                        <div className="spacemap" id="spacemap" />
                        {this.state.show ? <div className="nomap gray-three-color size-14">未找到该地址，请确认该地址是否有误</div> : null}
                    </List.Item>
                </List>
            );
        }
    }
}
