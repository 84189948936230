import React from "react";

import { PureComponent, transformAssetsUrl } from "@reco-m/core";
export namespace ImageAuto {
    export interface IProps extends PureComponent.IProps {
        src: any;
        height?: any;
        width?: any;
        radius?: any;
        isErrText?: any;
        isErr?: any;
        cutWidth?: any;
        cutHeight?: any;
        cropWidth?: any;
        cropHeight?: any;
    }

    export interface IState extends PureComponent.IState {
        src?: string;
        errorImage?: any;
        hasCacheAccess?: any;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            compress: false,
            src: "./assets/images/image_loading.gif",
            errorImage: false,
        };

        protected getInitState(_nextProps: Readonly<P>): Readonly<S> {
            return {
                errorImage: false,
            } as any;
        }
        componentDidMount() {
            setTimeout(() => {
                if (!this.state.src && this.props.src === "./assets/images/image_loading.gif") {
                    this.setState({ src: "./assets/images/error.png", errorImage: true });
                }
            }, 3000);
        }
        componentWillUnmount() {
            this.setState = () => false;
        }

        handleImageErrored() {
            this.setState({ src: "./assets/images/error.png", errorImage: true });
        }

        componentReceiveProps(nextProps: P) {
            if (
                nextProps &&
                typeof nextProps.src === "string" &&
                (nextProps.src.indexOf("./assets/images/error.png") !== -1 || nextProps.src === "" || nextProps.src === undefined)
            ) {
                // 如果外部传给组件是错误链接或""或者undefined则设置错误样式控制errorImage为ture
                this.setState({ src: "./assets/images/error.png", errorImage: true });
            } else {
                // 如果外部刷新了src则把错误样式控制移除
                this.setState({ src: "", errorImage: false });
            }
        }

        // 客户列表的图片，在请求错误时要显示传过来的文字不显示错误图片
        render(): React.ReactNode {
            const { src: target, className, isErrText, isErr, cutWidth, cutHeight, cropWidth, cropHeight } = this.props;
            let src = transformAssetsUrl((this.state && this.state.src) || target);
            if (!src) {
                src = "";
            } else if (cutWidth || cropWidth || cutHeight || cropHeight) {
                const v=2;
                src = `${src}?`
                cutWidth && (src = `${src}width=${cutWidth*v}&`);
                cutHeight && (src = `${src}height=${cutHeight *v}&`);
                cropWidth && (src = `${src}cropWidth=${cropWidth*v}&`);
                cropHeight && (src = `${src}cropHeight=${cropHeight *v}`);
            }
            
            return (
                <div
                    ref="height"
                    className={className ? className + " images-auto" : "images-auto"}
                    style={{
                        width: this.props.width,
                        height: "" + this.props.height,
                        borderRadius: this.props.radius,
                        backgroundImage: this.state && this.state.errorImage ? undefined : `url(${src})`,
                    }}
                >
                    {this.state && this.state.errorImage && isErr ? (
                        <span className="image-auto-error">{isErrText && isErrText.substring(0, 2)}</span>
                    ) : (
                        <img src={src} onError={this.handleImageErrored.bind(this)} style={{ width: "" + this.props.width, height: "" + this.props.height }} alt={""} />
                    )}
                    {this.state.errorImage && <img className="error-image" src={src} alt={""} />}
                    {this.props.children}
                </div>
            );
        }
    }
}

