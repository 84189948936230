import React from "react";

import produce from "immer";
import { isEqual } from "lodash";
import { PullToRefresh, ListView } from "antd-mobile";

import { NoData } from "../components";

import { ViewComponent } from "./view";

import { shake } from "../utils";

export namespace ListComponent {
    export interface IProps<S = any> extends ViewComponent.IProps<S> {
        middle?: boolean;
        scroll?: any;
    }

    export interface IState extends ViewComponent.IState {
        dataSource?: RECO.Mobile.ListView.DataSources;
        hasMore?: boolean;
        down?: boolean;
        refreshing?: boolean;
        pageSize?: number;
        currentPage?: number;
        key?: string;
    }

    export abstract class Base<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        static defaultProps = {
            ...ViewComponent.Base.defaultProps,
            classPrefix: "list",
            // middle: false
        } as any;

        listView;
        private _pullTimeout;
        private __state: any;

        protected middle = false;
        protected NoDataText = "暂无数据";
        protected loadingOptimize = false;

        protected getInitState(nextProps: Readonly<P>): Readonly<S> {
            const state = super.getInitState(nextProps);

            return produce(state as any, (draft) => {
                draft.dataSource = new ListView.DataSource({ rowHasChanged: (row1, row2) => row1 !== row2 });
            }) as any;
        }

        shouldUpdateData<T>(state: T, getDataBlob: (state: T) => any[] = resolveDataBlob) {
            const { dataSource } = this.state;
            if (dataSource && !isEqual(state, this.__state)) {
                this.__state = state;

                const dataBlob = getDataBlob(state);

                this.setState({ dataSource: dataSource!.cloneWithRows(Array.isArray(dataBlob) ? dataBlob : []) });
            }
        }

        renderBody(): React.ReactNode {
            return this.getListView();
        }

        getListView(middle: boolean = false): React.ReactNode {
            const { scroll = this.scroll } = this.props,
                state = this.getPageData(),
                { dataSource } = this.state || ({} as any);

            middle = middle || this.middle || this.props.middle!;
            // 首先判断是否优化为加载完数据不显示暂无数据兼容旧版本
            return getResolveData(state, "refreshing") !== false && this.loadingOptimize ? null : dataSource && dataSource.getRowCount() ? (
                middle && !scroll ? null : (
                    <ListView
                        ref={
                            middle
                                ? (el: any) => {
                                      el &&
                                          scroll &&
                                          (function (lvr) {
                                              lvr.componentWillUnmount(), (lvr.ScrollViewRef = scroll), lvr.componentDidMount();
                                          })(el.listviewRef.ListViewRef),
                                          (this.listView = el);
                                  }
                                : (el) => (this.listView = el)
                        }
                        className={middle ? "container-list" : undefined}
                        initialListSize={this.state.pageSize}
                        dataSource={this.state.dataSource}
                        renderFooter={this.renderListFooter.bind(this)}
                        renderRow={this.renderItemsContent.bind(this)}
                        style={{ height: "100%" }}
                        scrollRenderAheadDistance={500}
                        pullToRefresh={this.renderPullToRefresh(this.pullToRefresh.bind(this))}
                        onEndReached={this.onEndReached.bind(this)}
                        onEndReachedThreshold={200}
                        pageSize={this.state.pageSize}
                    />
                )
            ) : (
                <NoData.Component text={this.NoDataText || "暂无数据"} />
            );
        }

        protected getPageData(): S {
            return this.props.state as any;
        }

        scrollTo() {
            $(".am-list-view-scrollview:visible").scrollTop(0);

            setTimeout(() => {
                $(".am-list-view-scrollview:visible").scrollTop(0);
            }, 300);
        }

        abstract renderItemsContent(data?: any, e?: any, i?: number);

        renderPullToRefreshIndicator() {
            return {
                activate: iconSvg("松手刷新"),
                deactivate: iconSvg("下拉刷新"),
                release: iconSvg("刷新中...", "1"),
                finish: iconSvg("更新完成"),
            };
        }

        /**
         * 下拉刷新
         * @param onRefresh 刷新回调
         */
        renderPullToRefresh(onRefresh?: () => void): React.ReactNode {
            const { state } = this.props;

            this._pullTimeout && (clearTimeout(this._pullTimeout), (this._pullTimeout = null));

            return (
                <PullToRefresh
                    getScrollContainer={null!}
                    indicator={this.renderPullToRefreshIndicator() as any}
                    distanceToRefresh={40}
                    direction={this.state.down !== !1 ? "down" : ("up" as any)}
                    refreshing={this.state.refreshing && (getResolveData(state, "refreshing") as any)}
                    damping={50 as any}
                    onRefresh={() =>
                        typeof onRefresh === "function" &&
                        (this.setState({ refreshing: !0 }),
                        onRefresh(),
                        (this._pullTimeout = setTimeout(() => ((this._pullTimeout = null), this.setState({ refreshing: !1 })))),
                        shake())
                    }
                ></PullToRefresh>
            );
        }

        abstract pullToRefresh(...args);

        abstract onEndReached();

        /**
         * 列表底部
         */
        renderListFooter(): React.ReactNode {
            const { state } = this.props;
            return (
                <div className="text-center">
                    {getResolveData(state, "isLoading") && client.showloading !== false ? (
                        <div className="display-flex container-align-center container-justify-center">
                            <span className="list-loading" /> 正在加载...
                        </div>
                    ) : getResolveData(state, "hasMore") && client.showloading !== false ? (
                        <div className="display-flex container-align-center container-justify-center">
                            <span className="list-loading" /> 正在加载...
                        </div>
                    ) : (
                        <span className="loadmore__tips">——— 没有更多内容了 ———</span>
                    )}
                </div>
            );
        }
    }
}

export function resolveDataBlob(state: any) {
    return (state && state.items) || state;
}

export function getResolveData(state: any, object: any) {
    return state && state[`${object}`];
}

export function iconSvg(text, _animation?) {
    return (
        <svg
            width="40px"
            height="35px"
            style={{ marginTop: "-10px" }}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <g transform="translate(0, -25)">
                <g transform="rotate(0 50 50)">
                    <rect x="47.5" y="24" rx="4.75" ry="2.4" width="5" height="12" fill="#cccccc">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite" />
                    </rect>
                </g>
                <g transform="rotate(30 50 50)">
                    <rect x="47.5" y="24" rx="4.75" ry="2.4" width="5" height="12" fill="#cccccc">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite" />
                    </rect>
                </g>
                <g transform="rotate(60 50 50)">
                    <rect x="47.5" y="24" rx="4.75" ry="2.4" width="5" height="12" fill="#cccccc">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite" />
                    </rect>
                </g>
                <g transform="rotate(90 50 50)">
                    <rect x="47.5" y="24" rx="4.75" ry="2.4" width="5" height="12" fill="#cccccc">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite" />
                    </rect>
                </g>
                <g transform="rotate(120 50 50)">
                    <rect x="47.5" y="24" rx="4.75" ry="2.4" width="5" height="12" fill="#cccccc">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite" />
                    </rect>
                </g>
                <g transform="rotate(150 50 50)">
                    <rect x="47.5" y="24" rx="4.75" ry="2.4" width="5" height="12" fill="#cccccc">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite" />
                    </rect>
                </g>
                <g transform="rotate(180 50 50)">
                    <rect x="47.5" y="24" rx="4.75" ry="2.4" width="5" height="12" fill="#cccccc">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite" />
                    </rect>
                </g>
                <g transform="rotate(210 50 50)">
                    <rect x="47.5" y="24" rx="4.75" ry="2.4" width="5" height="12" fill="#cccccc">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite" />
                    </rect>
                </g>
                <g transform="rotate(240 50 50)">
                    <rect x="47.5" y="24" rx="4.75" ry="2.4" width="5" height="12" fill="#cccccc">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite" />
                    </rect>
                </g>
                <g transform="rotate(270 50 50)">
                    <rect x="47.5" y="24" rx="4.75" ry="2.4" width="5" height="12" fill="#cccccc">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite" />
                    </rect>
                </g>
                <g transform="rotate(300 50 50)">
                    <rect x="47.5" y="24" rx="4.75" ry="2.4" width="5" height="12" fill="#cccccc">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite" />
                    </rect>
                </g>
                <g transform="rotate(330 50 50)">
                    <rect x="47.5" y="24" rx="4.75" ry="2.4" width="5" height="12" fill="#cccccc">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite" />
                    </rect>
                </g>
            </g>
            <text x="8" y="80" fontSize="20" fill="#333333">
                {text}
            </text>
        </svg>
    );
}
