import React from "react";
import { Modal, ImagePicker, Flex, Icon} from "antd-mobile";

import {UploadrWrap, browser} from "@reco-m/core";

import {ToastInfo} from "../utils/index"

const [, iosVersion = "14"] = /\bos\s+(\d+)\w+\s+like\b/i.exec(navigator.appVersion) || [],
    version = +iosVersion;

export namespace PictureUploadWrap {
    export interface IProps extends UploadrWrap.IProps {
        id?: string;
        customUpload?: boolean;
    }

    export interface IState extends UploadrWrap.IState {
        files: any[];
        percentage?: number;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends UploadrWrap.Base<P, S> {
        componentDidMount() {
            const {onRef} = this.props as any;
            onRef && onRef(this)
        }
        showErrorMessage(content: string): void {
            ToastInfo(content, 3);
        }

        addFiles(files: any[]) {
            this.checkUploadFiles(
                () => files.length,
                () => files.reduce((a, b) => a + (b.file || b).size, 0)
            ) && this.uploadFiles(files);
        }

        protected getUploadFileData(file: any) {
            return file.file;
        }

        protected associateUploadFileData(file: any, target: any) {
            super.associateUploadFileData(file, target);

            target.thumbUrl = file.url;
            target.orientation = version >= 13 ? 0 : file.orientation;
            target.original = file;
        }

        onChange(files: any[], operationType: string, index: number) {

            if (operationType === "add") {
                this.addFiles(files);
            } else if (operationType === "remove") {
                this.removeFile(this.state.files[index]);
            }
        }

        getFiles(files: any[]) {
            return files.map((file) => Object.create(file, {url: {value: file.thumbUrl}}));
        }

        onPreview0(index: number, _: any[]) {
            super.onPreview0(index, this.state.files);
        }
        clearFiles() {
            let {files} = this.state;

            files && files.map(item => {
                this.removeFile(item);

            })
            
            this.setState({files: []})
        }

        renderUpload(): React.ReactNode {
            const {files} = this.state,
                {className, multiple, children, readonly, ...props} = this.props as any,
                fileNumLimit = this.attachUploadService?.fileNumLimit;
            let captureProps = {};
            if((browser.versions.weChatMini && browser.versions.android)){
                captureProps = {capture: "camera"}
            }

            return (
                <ImagePicker
                    {...props}
                    {...captureProps}
                    className={this.classnames(className, this.getClassSet(), readonly ? "readonly" : "")}
                    files={this.getFiles(files)}
                    onChange={this.onChange.bind(this)}
                    onImageClick={this.onPreview0.bind(this)}
                    multiple={multiple}
                    disableDelete={readonly}
                    selectable={!readonly && (!fileNumLimit || fileNumLimit > files.length)}
                />
            );
        }

        renderUploadBtn(): React.ReactNode {
            return null;
        }

        touchStop(e) {
            $(e).parents('.upload-modal')
                .on('touchstart', (e) => e.stopPropagation())
                .on('touchmove', (e) => e.stopPropagation())
                .on('touchend', (e) => e.stopPropagation())
        }

        renderModal() {
            return (
                <Modal
                    title={
                        <Flex>
                            <Flex.Item>
                                预览 <span className="size-12 text-error">(温馨提示：双击可放大，拖动滚动条预览大图)</span>
                            </Flex.Item>
                            <a onClick={this.onCancel.bind(this)}>
                                <Icon type={"cross"}/>
                            </a>
                        </Flex>
                    }
                    wrapClassName="upload-modal"
                    visible={this.attachDataService.previewVisible}
                    onClose={this.onCancel.bind(this)}
                >
                    <div className='container-fill' ref={(e) => this.touchStop(e)}>
                        {this.renderModalBody()}
                    </div>
                </Modal>
            );
        }
    }
}
