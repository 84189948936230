import { router } from "dva";

import { loadLazyModule } from "@reco-m/core-ui";

function RouterConfig({ history }: any) {
    return (
        <router.Router history={history}>
            <router.Switch>
                <router.Route path="/index" component={loadLazyModule(() => import("@reco-m/ipark-white-home-all"))} />
                <router.Redirect to="/index" />
            </router.Switch>
        </router.Router>
    );
}

export default RouterConfig;
