import React from "react";
import { WingBlank, WhiteSpace } from "antd-mobile";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { PureComponent } from "@reco-m/core";

export enum SkeletonType {
    list = "list",
    circle = "circle",
}

export namespace Skeletons {
    export interface IProps extends PureComponent.IProps {
        type?: SkeletonType;
        count?: number;
        color?: string;
        highlightColor?: string;
        height?: number;
        width?: number;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            type: SkeletonType.list,
            count: 1,
            color: "#eee",
            highlightColor: "f5f5f5",
        };

        renderCircleItems(count): React.ReactNode {
            let items: any = [];

            for (let i = 0; i < count; i++) {
                items.push(1);
            }

            return items.map((_, index) => {
                return <Skeleton key={index} circle={true} height={50} width={50} />;
            });
        }

        render(): React.ReactNode {
            const { type, count, color, highlightColor, height, width } = this.props;

            return type === SkeletonType.list ? (
                <WingBlank>
                    <WhiteSpace />
                    <SkeletonTheme color={color} highlightColor={highlightColor}>
                        <p>
                            <Skeleton count={count} height={height} width={width} />
                        </p>
                    </SkeletonTheme>
                    <WhiteSpace />
                </WingBlank>
            ) : (
                <WingBlank>
                    <WhiteSpace />
                    <div className="grid-box">{this.renderCircleItems(count)}</div>
                    <WhiteSpace />
                </WingBlank>
            );
        }
    }
}
