import React from "react";

import { BasePicture } from "@reco-m/core";
import {ToastInfo} from "../utils/index"

import { getPictureDefaultProps } from "./util";
import { PictureUploadWrap } from "./picture-upload.wrap";

export namespace Picture {
    export interface IProps extends BasePicture.IProps {
        customUpload?: boolean;
    }

    export interface IState extends BasePicture.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends BasePicture.Component<P, S> {
        static defaultProps = getPictureDefaultProps();

        componentDidMount() {
            const {onBasePicturRef} = this.props as any;
            onBasePicturRef && onBasePicturRef(this)
        }

        protected onShowErrorMessage(msg: string): void {
            ToastInfo(msg);
        }

        render(): React.ReactNode {
            this.attachDataService
            return <PictureUploadWrap.Component {...this.props as any} />;
        }
    }
}
