import React from "react";

import { PureComponent } from "@reco-m/core";

import { Badge } from "./badge";
import { Icon } from "./icon";

export namespace TabBarItem {
    export interface IProps extends PureComponent.IProps {
        component?: any;
        icon?: any; // icon name
        title?: string;
        href?: string;
        eventKey?: any;
        badge?: string | number;
        badgeStyle?: string;
        selected?: boolean; // alias of `active`
        selectedicon?: any; // not supported now
        onAction?: Function;
        to?: string;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static defaultProps = {
            classPrefix: "tabbar",
            component: "span",
            // tslint:disable-next-line:no-empty
            onAction: () => {},
        } as any;

        renderBadge() {
            let { badge, badgeStyle } = this.props as any;

            return badge ? (
                <Badge.Component amStyle={badgeStyle || "alert"} rounded>
                    {badge}
                </Badge.Component>
            ) : null;
        }

        renderIcon() {
            let { icon, selected, selectedicon } = this.props;
            return typeof icon === "string" ? (
                <Icon.Component name={icon} key="tabbarIcon">
                    {this.renderBadge()}
                </Icon.Component>
            ) : selected ? (
                selectedicon
            ) : (
                icon
            );
        }

        renderTitle() {
            let labelClassName = this.prefixClass("label"),
                { title } = this.props;

            return title ? (
                <span className={labelClassName} key="tabbarTitle">
                    {title}
                </span>
            ) : null;
        }

        render() {
            let classSet = this.getClassSet(true),
                { component: Component, className, ...props } = this.props as any;

            delete props.classPrefix;
            delete props.badge;
            delete props.badgeStyle;
            delete props.eventKey;
            delete props.onAction;

            Component = this.props.href ? "a" : Component;

            return (
                <Component {...props} className={this.classnames(classSet, className, this.prefixClass("item"))}>
                    {this.renderIcon()}
                    {this.renderTitle()}
                </Component>
            );
        }
    }
}

export namespace TabBar {
    export const Item = TabBarItem.Component;

    export interface IProps extends PureComponent.IProps {
        component?: any;
        amStyle?: string;
        onAction?: Function;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static defaultProps = {
            classPrefix: "tabbar",
            component: "nav",
            // tslint:disable-next-line:no-empty
            onAction: () => {},
        };

        render() {
            let classSet = this.getClassSet(),
                { component: Component, className, children, onAction, ...props } = this.props as any;

            delete props.classPrefix;
            delete props.amStyle;

            return (
                <Component {...props} className={this.classnames(classSet, className)}>
                    {React.Children.map(children, (child: any, index) => {
                        let { eventKey, onClick, ...propsc } = child.props,
                            clickHandler = onClick || onAction,
                            key = eventKey || index;
                        eventKey = eventKey || key;
                        return <Item {...propsc} onClick={clickHandler.bind(null, eventKey)} key={index} eventKey={eventKey} />;
                    })}
                </Component>
            );
        }
    }
}
