import React from "react";

import { Result } from "antd-mobile";

import { PureComponent } from "@reco-m/core";

export namespace NoData {
    export interface IProps extends PureComponent.IProps {
        icon?: any;
        text?: any;
        className?: string;
        complete?: any;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            text: "暂无数据",
            className: "",
        };

        render(): React.ReactNode {
            const { icon, text, className, complete } = this.props;
            return (
                <Result
                    className={`no-data  ${className}`}
                    message={
                        complete ? (
                            complete
                        ) : (
                            <div>
                                <div>{icon ? icon : <img width={150} src={"assets/images/no-dl.svg"} />}</div>
                                {text}
                            </div>
                        )
                    }
                />
            );
        }
    }
}
